.generic-banner {
  position: relative;
  overflow: hidden;
  position: relative;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  max-height: 300;
}
.generic-banner div.info {
  display: flex;
  align-items: center;
}
.generic-banner div.info > div {
  position: absolute;
  right: 0;
  left: 0;
  margin: 30px;
  z-index: 1;
  text-align: center;
}
.generic-banner div.info > div h3 {
  color: #ffffff;
  display: block;
  margin: 0 auto;
  font-weight: 600;
  font-size: 35px;
}
