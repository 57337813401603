.page-tab {
  list-style: none;
  padding: 0;
}
.page-tab > li:after {
  content: "|";
  margin-left: 20px;
  color: #555555 !important;
  font-weight: normal !important;
}
.title-grey {
  color: #555555;
  font-weight: 700;
}
.beverage-package1 th{
  font-family: 'Avenir Next Bold';
  font-weight: 700;
  background-color: #d7182a;
  color: white;
  border: 1px solid #ddd;
  padding: 16px;
  text-align: center;
}
tbody, td, tfoot, th, thead, tr{
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.beverage-package1 td:first-child{
  width: 180px;
}
.beverage-package1 td{
  border: 1px solid #ddd;
  padding: 32px 32px 16px 32px;
  text-align: left;
  vertical-align: top;
}
.title1 {
  font-family: 'Avenir Next Bold';
  font-weight: 700;
  color: #d7182a;
  font-weight: bold;
}
.price{
  color: #d7182a;
  font-family: 'Avenir Next Bold';
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
}
.content-info-holder .info {
  background: #FFFFFF;
  min-height: 400px;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius)!important;
  border-top-right-radius: var(--bs-border-radius)!important;
}
.content-info-holder .info > .title {
  background: #D7182A;
  color: #FFFFFF;
  padding: 15px 30px;
}
.content-info-holder .info > .title p {
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 1px;
}
.content-info-holder .info > ol {
  margin: 20px;
}
.d-block {
  display: block!important;
}
.content-info-holder .info > ol li {
  margin-bottom: 5px;
}


.content-info-holder .info > .title p span {
  font-size: 12px;
  font-family: 'Avenir Next Light';
  letter-spacing: 2px;
}
.page-tab > li:last-child:after {
  content: "";
  margin-left: 0;
}
.page-tab > li {
  display: inline-block;
  margin-right: 10px;
  color: #555555;
  cursor: pointer;
  letter-spacing: 2px;
}
.text-center {
  text-align: center!important;
}