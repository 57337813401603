.section-red .title-color {
  color: #ffffff;
}
.destination-package a > div {
  position: relative;
}
.destination-package .info {
  position: absolute;
  left: 20px;
  bottom: 0;
}
destination-package a > div {
  position: relative;
}
p.place {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}
