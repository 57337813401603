.page-tab {
  list-style: none;
  padding: 0;
}
.page-tab > li:after {
  content: "|";
  margin-left: 20px;
  color: #555555 !important;
  font-weight: normal !important;
}
.event-holder {
  position: relative;
  display: flex;
  align-items: bottom;
}
.event-holder a {
  display: block;
  text-decoration: none !important;
  outline: none !important;
  width: 100%;
  overflow: hidden;
}
.rounded {
  border-radius: 10px!important;
}
.event-holder a > img {
  width: 100%;
  max-width: 100%;
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8);
  transition: 0.4s ease;
}
.event-holder .info {
  position: absolute;
  bottom: 25px;
  left: 30px;
  color: #FFFFFF;
}
.destination-card {
  position: relative;
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 15px;
}

.destination-card .info p > span {
  color: #D7182A;
  margin-left: 5px;
  margin-right: 5px;
}
.destination-card .info {
  padding: 15px 20px;
  min-height: 220px;
}
.destination-card .info p.price {
  color: #000000;
}
p{
  margin-bottom: 0px;
}
.title-grey {
  color: #555555;
  font-weight: 700;
}
.content-info-holder .info {
  background: #FFFFFF;
  min-height: 400px;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius)!important;
  border-top-right-radius: var(--bs-border-radius)!important;
}
.content-info-holder .info > .title {
  background: #D7182A;
  color: #FFFFFF;
  padding: 15px 30px;
}
.content-info-holder .info > .title p {
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 1px;
}
.content-info-holder .info > ol {
  margin: 20px;
}
.d-block {
  display: block!important;
}
.content-info-holder .info > ol li {
  margin-bottom: 5px;
}


.content-info-holder .info > .title p span {
  font-size: 12px;
  font-family: 'Avenir Next Light';
  letter-spacing: 2px;
}
.page-tab > li:last-child:after {
  content: "";
  margin-left: 0;
}
.page-tab > li {
  display: inline-block;
  margin-right: 10px;
  color: #555555;
  cursor: pointer;
  letter-spacing: 2px;
}
.text-center {
  text-align: center!important;
}