.section-grey {
  background: #e9e9e9;
}
.title-color {
  color: #D7182A;
  font-weight: 600;
}
p.destination-label {
  letter-spacing: 1px;
  border-bottom: 2px solid #E5BF83;
  color: #9D9D9D;
  padding-bottom: 5px;
  display: inline-block;
}
p.download-itinerary a {
  display: inline-block;
  color: #D7182A;
  text-decoration: none !important;
  margin: 0 10px 10px 0;
  border-radius: 50px;
  border: 1px solid;
  padding: 6px 20px;
}
p.download-itinerary a.langEN {
  display: inline-block !important;
}
