.py-md-5 {
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
}
.title-color {
  color: #D7182A;
}
.text-center {
  text-align: center!important;
}
#icon-lists > div.col-md-3 > div > h3 {
  color: #D6182A;
  font-weight: 600;
  margin: 0 auto;
  padding-top: 30px;
}
.arabic{
  direction: rtl;
}