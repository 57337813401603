.box-bring {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}
.box-bring .box-header-cruise {
  background: #d7182a;
}
.special-care .box-bring-content {
  padding: 2.5rem 0;
  display: flex;
  justify-content: space-around;
  height: auto;
}
.special-care .box-bring-content .special-care-item {
  border-right: 1px solid #ccc;
  padding: 0 2.5rem;
}
.title-text{
  color:red;
  font-weight:bold;
}

.text-center {
  text-align: center !important;
}
.box-bring-item .box-bring-item-list:first-child {
  border-right: 1px solid #dee2e6 !important;
}

.box-bring-item .box-bring-item-list {

  width: 50%;
}
.box-bring-content .special-care-item {
  border-right: 1px solid #ccc;
  padding: 2.5rem;
}
.box-bring-content {
  background: #fff;
  height: calc(100% - 52px);
  overflow: hidden;
}
.flex-direction-column {
  flex-direction: column;
}
.box-bring-item {
  display: flex;
}
.box-header-cruise h2 {
  font-family: "Avenir Next Bold";
  font-size: 18px;
  color: #fff;
  margin: 0;
}
.box-bring-item .box-bring-item-list .box-bring-content-item:last-child {
  border-bottom: 0 none !important;
}

.box-bring-item .box-bring-item-list .box-bring-content-item {
  border-bottom: 1px solid #dee2e6 !important;
  height: 100%;
}
