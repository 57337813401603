@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900&display=swap");

.brand-link .brand-image {
  box-shadow: none !important;
}
p{
  margin-bottom: 0px;
}
.ck-editor__editable {
  height: 100%;
}
.ck-editor__main {
  height: 100%;
}
.ck.ck-editor {
  height: 100%;
}
.main-sidebar {
  background-color: #000;
  box-shadow: none !important;
}
.datatite > p {
  margin-bottom: 0px !important;
}
.w-100 {
  width: "100%";
}
.ant-picker {
  width: 100%;
  border-radius: 5px;
  border: 1px solid grey;
  color: black;
}

.ant-select-selection-placeholder {
  color: black;
}
/* .ant-picker-cell-disabled {
  background: red !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 100px !important;
} */
.ant-picker-input > input::placeholder {
  color: black !important;
  opacity: 1; /* Firefox */
}
.ant-picker .ant-picker-suffix {
  color: black !important;
}

.ant-picker-input > input {
  font-size: 16px;
}

.ant-picker:hover {
  border-color: black;
}

.ant-picker-focused {
  border-color: black;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .ant-select-selector {
  display: none !important;
} */

.nav-sidebar .nav-item > .nav-link {
  transition: all 200ms ease-in-out;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.25);
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-pills .nav-link {
  font-weight: 600;
}

.nav-pills .nav-link i {
  font-weight: normal;
  font-size: 15px !important;
}

.main-footer {
  background-color: #b3b3b3;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: none;
}

.custom_date {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: #17a2b8;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.custom_date input[type="date"] {
  font-size: 0px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.custom_date input[type="date"]::-webkit-inner-spin-button,
.custom_date input[type="date"]::-webkit-calendar-picker-indicator {
  width: 15px;
  height: 15px;
}

.cdd_wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.cdd_wrap .cdd_box {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  padding: 10px;
  min-width: 170px;
  opacity: 0;
  transition: all 150ms ease-in-out;
}

.cdd_wrap .cdd_box ul {
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cdd_wrap .cdd_box ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.cdd_wrap .cdd_box ul li .number_box {
  display: inline-block;
}

.cdd_wrap .cdd_box ul li .number_box .box_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  overflow: hidden;
  width: 90px;
}

.cdd_wrap .cdd_box ul li .number_box .box_inner input {
  border: none;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  padding: 1px 5px;
  width: 40px;
  height: 25px;
  outline: none;
  border: solid 1px #6c757d;
  border-left: none;
  border-right: none;
}

.cdd_wrap .cdd_box ul li .number_box .box_inner button {
  border: none;
  outline: none;
  font-size: 10px;
  width: 25px;
  height: 25px;
  background-color: #6c757d;
  color: #fff;
}

.cdd_wrap .cdd_box ul li .number_box .box_inner button:hover {
  background-color: #17a2b8;
}

.cdd_wrap:hover .cdd_box {
  top: 110%;
  opacity: 1;
}

.package_table table.dataTable > thead > tr > th:not(.sorting_disabled),
.package_table table.dataTable > thead > tr > td:not(.sorting_disabled) {
  padding: 0.75rem;
  cursor: text;
}

.package_table table.dataTable > thead > tr > th:not(.sorting_disabled)::after,
.package_table table.dataTable > thead > tr > th:not(.sorting_disabled)::before,
.package_table table.dataTable > thead > tr > td:not(.sorting_disabled)::after,
.package_table
  table.dataTable
  > thead
  > tr
  > td:not(.sorting_disabled)::before {
  content: none;
}

.package_table thead tr th {
  white-space: nowrap;
}

.package_table table tbody tr td {
  position: relative;
  white-space: nowrap;
}

:root {
  --white: #fff;
  --grey: rgb(247 244 243);
  --black: rgb(0 0 0);
  --deep-grey: rgb(84 87 78);
  --red: rgb(215 24 42);
  --golden: rgb(254 208 122);
  --deep-golden: rgb(229 191 131);
  --light-golden: rgb(230 214 192);
}

::-moz-selection {
  background-color: var(--light-golden);
}

::selection {
  background-color: var(--light-golden);
}

a {
  color: var(--red);
}

.nav-sidebar .nav-link > .right,
.nav-sidebar .nav-link > p > .right {
  top: 9px;
}

.card-title {
  font-weight: 700;
}

/*--------------------*/
/***** Style for Vouchers Page ****/
.v_count {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}

.v_count .count_box {
  background-color: var(--white);
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.v_count .count_box h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  margin: 0;
}

.v_count .count_box h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  margin: 10px 0;
}

.v_count .count_box h5 {
  font-size: 24px;
  font-weight: 800;
  color: var(--white);
  margin: -15px;
  margin-top: 0;
  padding: 8px 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.v_count .count_box img {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.2;
  width: 70px;
}

@media (max-width: 1325px) {
  .v_count {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1125px) {
  .v_count {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 700px) {
  .v_count {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .v_count {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

.table_holder {
  overflow: auto;
}

.table_holder::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table_holder::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 100px;
}

.table_holder::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table_holder .table_action_btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.table_holder .table_action_btn .btn {
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 0;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  background-color: var(--deep-golden);
  color: var(--black);
  font-weight: normal;
}

.table_holder .table_action_btn .btn.delete_btn {
  background-color: var(--red);
  color: var(--white);
}

.table_holder .table_action_btn .btn.success_btn {
  background-color: var(--green);
  color: var(--white);
}

.divcontainer {
  /* overflow-x: scroll; */
  overflow-y: auto;
  transform: rotateX(180deg);
}

.divcontainer table {
  transform: rotateX(180deg);
}

.table_holder .table_action_btn .btn.copy_btn {
  width: auto;
  padding: 0px 10px;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--black);
  color: var(--white);
}

.two_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.two_item::after {
  content: none;
}

.theme_modal .modal-dialog .modal-content {
  box-shadow: none;
  border-radius: 0;
  border: none;
}

.theme_modal .modal-dialog .modal-content .cls_modal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border: none;
  width: 35px;
  height: 35px;
  font-size: 22px;
  border-radius: 0 0 0 15px;
  background-color: var(--red);
  color: var(--white);
  transition: all 200ms ease-in-out;
}

.theme_modal .modal-dialog .modal-content .cls_modal:hover {
  background-color: var(--black);
}

.theme_modal .modal-dialog .modal-content .modal_title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 15px 15px;
  padding-bottom: 0;
  margin: 0;
  color: var(--black);
}

.theme_modal .modal-dialog .modal-content .modal-body {
  padding: 20px;
  padding-bottom: 25px;
}

.theme_modal .modal-dialog .modal-content .modal-body.success_body {
  padding: 40px 15px;
}

.theme_modal .modal-dialog .modal-content .modal-body.success_body .suc_img {
  margin: 0;
  text-align: center;
}

.theme_modal
  .modal-dialog
  .modal-content
  .modal-body.success_body
  .suc_img
  img {
  width: 90px;
  height: 90px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.theme_modal .modal-dialog .modal-content .modal-body.success_body .suc_img h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0;
  margin-top: 20px;
}

.theme_modal .modal-dialog .modal-content .modal-footer .btn {
  border-radius: 0px;
  background-color: var(--deep-golden);
  color: var(--black);
  font-weight: normal;
  padding: 6px 15px;
  min-width: 100px;
}

.theme_modal .modal-dialog .modal-content .modal-footer .btn.modal_submit {
  background-color: var(--red);
  color: var(--white);
}

.theme_modal .modal-dialog .modal-content .modal-footer .btn:hover {
  background-color: var(--black);
  color: var(--white);
}

@media (max-width: 575px) {
  .theme_modal .modal-dialog .modal-content .cls_modal {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .theme_modal .modal-dialog .modal-content .modal_title {
    font-size: 20px;
  }

  .theme_modal .modal-dialog .modal-content .modal-body {
    padding: 15px;
    padding-bottom: 20px;
  }

  .theme_modal .modal-dialog .modal-content .modal-footer .btn {
    font-size: 15px;
  }
}

.input_form .fieldrow {
  position: relative;
  margin-bottom: 20px;
}

.input_form .fieldrow .f_label {
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 3px;
  color: var(--deep-grey);
}

.input_form .fieldrow .f_data {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: var(--black);
  margin: 0;
}

.input_form .fieldrow .f_input {
  display: block;
  width: 100%;
  outline: none;
  border: solid 1px #ddd;
  background-color: transparent;
  padding: 10px 15px;
  padding-bottom: 11px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  min-height: 45px;
  transition: all 200ms ease-in-out;
}

.input_form .fieldrow .f_input:focus {
  border-color: var(--deep-golden);
}

.input_form .fieldrow .multiple_dates {
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px 8px;
  flex-wrap: wrap;
  margin-top: 5px;
}

.input_form .fieldrow .multiple_dates li {
  display: block;
  background-color: #ddd;
  color: var(--black);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  padding: 6px 8px;
  padding-bottom: 5px;
  border-radius: 3px;
}

@media (max-width: 575px) {
  .input_form .fieldrow {
    margin-bottom: 15px;
  }
}

.select_box {
  position: relative;
}

.select_box select {
  -webkit-appearance: none;
  z-index: 2;
  background-color: transparent;
  position: relative;
}

.select_box::after {
  content: "\ea4e";
  font-family: remixicon !important;
  position: absolute;
  top: 8px;
  right: 13px;
  font-size: 20px;
  z-index: 1;
}

button,
.btn,
a,
input,
select {
  transition: all 200ms ease-in-out;
  outline: none !important;
}

.price_type_input {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.price_type_input .select_box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 100%;
  border-radius: 0;
  background-color: var(--deep-golden);
}

.price_type_input .select_box select {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 14px 8px;
  color: var(--black);
  font-size: 13px;
  font-weight: 700;
}

.price_type_input .select_box select option {
  color: #000;
  font-weight: 600;
}

.price_type_input .select_box:after {
  top: 10px;
  right: 5px;
  font-size: 16px;
}

.price_type_input input {
  display: block;
  width: 100%;
  outline: none;
  border: solid 1px #ddd;
  padding: 10px 15px;
  padding-left: 85px;
  padding-bottom: 11px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  height: 45px;
  transition: all 200ms ease-in-out;
  background-color: transparent;
}

.price_type_input input:focus {
  border-color: var(--deep-golden);
}

.denom_box {
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 3px;
  margin-top: 15px;
}

.denom_box p {
  font-size: 14px;
  font-weight: 700;
  display: block;
  position: absolute;
  top: -13px;
  left: 5px;
  margin: 0;
  background-color: #fff;
  padding: 0 6px;
}

.denom_box ul {
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.denom_box ul li {
  display: flex;
  align-items: center;
  gap: 15px;
}

.denom_box ul li span {
  width: 100px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.denom_box ul li input {
  display: block;
  width: calc(100% - 65px);
  outline: none;
  border: solid 1px #ddd;
  background-color: transparent;
  padding: 10px 15px;
  padding-bottom: 11px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  height: 45px;
  transition: all 200ms ease-in-out;
}

.denom_box ul li input:focus {
  border-color: var(--deep-golden);
}

.blackout_date {
  padding: 3px 0;
  min-height: 110px;
}

.blackout_date .date_picker {
  position: relative;
}

.blackout_date .date_picker .f_input {
  padding-right: 70px;
}

.blackout_date .date_picker .btn {
  position: absolute;
  top: 6px;
  right: 5px;
  border-radius: 50px;
  padding: 5px 15px;
  font-size: 14px;
  background-color: var(--red);
  color: var(--white);
}

.blackout_date .date_picker .btn:hover {
  background-color: var(--black);
}

.blackout_date ul {
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 10px;
}

.blackout_date ul li {
  display: block;
  padding: 4px 10px;
  padding-bottom: 3px;
  font-size: 13px;
  font-weight: 700;
  background-color: var(--deep-golden);
  color: var(--black);
  position: relative;
  border-radius: 50px;
  overflow: hidden;
}

.blackout_date ul li button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 15px;
  border: none;
  width: 25px;
  line-height: 25px;
  height: 100%;
  padding: 0;
  border-radius: 50px;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.blackout_date ul li:hover button {
  opacity: 1;
}

.preview {
  font-size: 14px;
  font-weight: 600;
}

/**** Style for Voucher Tabs ****/
.voucher_nav {
  display: flex;
  align-items: center;
  gap: 1px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.voucher_nav li .nav_btn {
  background-color: var(--deep-golden);
  color: var(--black);
  border: none;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-width: 100px;
  font-weight: 600;
  transition: all 250ms ease-in-out;
}

.voucher_nav li .nav_btn.active {
  background-color: var(--red);
  color: var(--white);
}

.card {
  border-radius: 0px;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  border-radius: 0px;
}

.add_v_btn {
  background-color: var(--black);
  color: var(--white);
  font-size: 15px;
  border-radius: 0px;
}

.export_v_btn {
  background-color: var(--golden);
  color: var(--black);
  font-size: 15px;
  border-radius: 0px;
}

.export_v_btn:hover {
  background-color: var(--deep-golden);
  color: var(--black);
}

.add_v_btn:hover {
  background-color: var(--red);
  color: var(--white);
}

.upload_btn {
  background-color: var(--deep-golden);
  color: var(--black);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.upload_btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  font-size: 0px;
}

.upload_btn span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 14px;
  cursor: pointer;
}

.upload_btn:hover {
  background-color: var(--red);
  color: var(--white);
}

table.dataTable {
  margin: 15px 0 !important;
}

table.dataTable thead tr th {
  font-size: 14px;
  padding: 10px;
}

.custom-switch .custom-control-label::after {
  background-color: var(--black);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--red);
  border-color: var(--red);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--white);
}

table.dataTable .badge {
  font-weight: normal;
  border-radius: 0;
}

.dataTables_wrapper .btn-group > .btn {
  font-size: 14px;
  background-color: rgb(229, 191, 131);
  color: #000 !important;
  font-weight: 600;
  border: none;
}

.dataTables_wrapper .btn-group > .btn:hover {
  background-color: rgb(183, 151, 101);
}

.dataTables_wrapper .btn-group > .btn.dropdown-toggle::after {
  vertical-align: middle;
  margin-left: 5px;
}

div.dt-button-collection {
  padding: 0;
}

div.dt-button-collection .dropdown-item {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
}

div.dt-button-collection .dropdown-item.active {
  background-color: var(--red);
}
.main_submit2 {
  border: 1px solid var(--red);
  color: var(--red);
  border-radius: 0px;
  font-size: 15px;
}
.main_submit3 {
  color: var(--red);
  border-radius: 0px;
  font-size: 15px;
}
.main_submit {
  background-color: var(--red);
  color: var(--white);
  border-radius: 0px;
  font-size: 15px;
}

.main_submit:hover {
  background-color: var(--black);
  color: var(--white);
}

.nav-sidebar .nav-link {
  padding: 8px;
}

.nav-sidebar .nav-link p {
  font-size: 15px;
}

.nav-sidebar .nav-treeview > .nav-item > .nav-link > .nav-icon {
  width: auto;
  font-size: 14px;
  margin: 0;
  margin-right: 6px;
}

/***** Style for Login Page ****/
.login_wrap {
  position: relative;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
}

.login_wrap .bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  opacity: 0.3;
}

.login_wrap .main-footer {
  margin: 0 !important;
  position: relative;
}

.login_wrap .login_inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
}

.login_wrap .login-box {
  width: 100%;
  max-width: 450px;
  padding: 50px 30px;
  text-align: center;
  position: relative;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}

.login_wrap .login-box::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.login_wrap .login-box .box_inner {
  position: relative;
}

.login_wrap .login-box .box_inner .box_img {
  width: 100%;
  max-width: 280px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  margin-bottom: 20px;
}

.login_wrap .login-box .box_inner .login_title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 15px;
}

.login_wrap .login-box .box_inner .form-control {
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0px;
  border: solid 1px #ddd;
  color: #000;
  padding: 12px 15px;
  transition: all 200ms ease-in-out;
}

.login_wrap .login-box .box_inner .form-control:focus {
  border-color: rgb(215, 24, 42);
}

.login_wrap .login-box .box_inner .input-group-text {
  background-color: #000;
  color: #fff;
}

.login_wrap .login-box .box_inner .icheck-primary {
  text-align: left;
  margin: 0 !important;
  margin-top: 9px !important;
}

.login_wrap
  .login-box
  .box_inner
  [class*="icheck-"]
  > input:first-child
  + input[type="hidden"]
  + label::before,
.login_wrap
  .login-box
  .box_inner
  [class*="icheck-"]
  > input:first-child
  + label::before {
  background-color: #fff;
  border: solid 1px #000;
  width: 18px;
  height: 18px;
  margin-left: -28px;
  top: 1px;
}

.login_wrap
  .login-box
  .box_inner
  .icheck-primary
  > input:first-child:checked
  + input[type="hidden"]
  + label::before,
.login_wrap
  .login-box
  .box_inner
  .icheck-primary
  > input:first-child:checked
  + label::before {
  background-color: rgb(215, 24, 42);
  border-color: rgb(215, 24, 42);
}

.login_wrap
  .login-box
  .box_inner
  [class*="icheck-"]
  > input:first-child:checked
  + input[type="hidden"]
  + label::after,
.login_wrap
  .login-box
  .box_inner
  [class*="icheck-"]
  > input:first-child:checked
  + label::after {
  width: 6px;
  height: 10px;
  top: -1px;
}

.login_wrap
  .login-box
  .box_inner
  label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 600;
  font-size: 15px;
  color: #000;
}

.login_wrap .login-box .box_inner .btn_sign {
  background-color: #000;
  background-color: rgb(215, 24, 42);
  color: #fff;
  width: 100%;
  border-radius: 0px;
  transition: all 200ms ease-in-out;
}

.login_wrap .login-box .box_inner .btn_sign:hover {
  background-color: rgb(229, 191, 131);
  color: #000;
}

.login_wrap .login-box .box_inner a:hover {
  color: #000;
}

@media (max-width: 575px) {
  .login_wrap .login-box {
    padding: 15px;
  }
}

.banner_img {
  width: 150px;
  height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.upload_box {
  position: relative;
  border: dashed 2px #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  border-radius: 5px;
  margin-top: 5px;
}

.upload_box input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload_box i {
  font-size: 40px;
  color: #495057;
  margin-bottom: 5px;
  height: 40px;
  line-height: 40px;
}

.upload_box span {
  font-size: 14px;
  font-weight: normal;
  color: #495057;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-size: 14px;
  font-weight: 700;
  padding-top: 2px;
}

.custom-control-input {
  box-shadow: none !important;
  outline: none;
}

.custom-control-input-danger:checked ~ .custom-control-label::before {
  background-color: rgb(215, 24, 42);
  border-color: rgb(215, 24, 42);
}

.note-editor .note-editing-area .note-editable {
  min-height: 100px;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 2px;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple,
.select2-container--default.select2-container--focus
  .select2-selection--single {
  border-color: var(--deep-golden);
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  display: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  margin: 0;
  padding: 10px 12px;
  line-height: 16px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: var(--red);
  border-color: var(--red);
  font-size: 14px;
  padding: 2px 10px;
  margin-top: 4px;
  border-radius: 0px;
}

.select2-container--default .select2-results__option {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 12px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default
  .select2-results__option--highlighted[aria-selected]:hover {
  background-color: var(--deep-golden);
  color: #000;
}

.selection {
  position: relative;
}

.selection::before {
  content: "\ea4e";
  font-family: remixicon !important;
  color: #000;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
}

.add_iti_table {
  overflow: auto;
}

.add_iti_table table {
  border-color: #ddd;
}

.add_iti_table table thead tr th {
  background-color: var(--deep-golden);
  color: #000;
  border-color: #ddd;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 700;
}

.add_iti_table table tbody tr td {
  padding: 6px;
}

.add_iti_table table tbody tr td input {
  background-color: var(--white);
  border: solid 1px #ddd;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  padding: 8px;
  width: 100%;
  transition: all 200ms ease-in-out;
  border-radius: 3px;
}

.add_iti_table table tbody tr td input:focus {
  border-color: var(--deep-golden);
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active,
[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active:hover {
  background-color: var(--red);
  color: var(--white);
}

table tbody tr td .cruise_dates {
  background-color: #5d5d5d;
  color: var(--white);
  padding: 4px 6px;
  font-size: 12px;
  font-weight: normal;
  border-radius: 3px;
}

.add_iti {
  margin-top: 10px;
  margin-bottom: 20px;
}

.add_iti .iti_input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 500px;
}

.add_iti .iti_input .input_inner {
  margin-top: 5px;
}

.add_iti .iti_input .input_inner input {
  display: inline-block;
  outline: none;
  border: solid 1px #ddd;
  background-color: transparent;
  padding: 10px 12px;
  padding-bottom: 11px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0px;
  height: 40px;
  transition: all 200ms ease-in-out;
  width: calc(100% - 95px);
  vertical-align: middle;
}

.add_iti .iti_input .input_inner input:focus {
  border-color: var(--deep-golden);
}

.add_iti .iti_input .input_inner .btn {
  display: inline-block;
  vertical-align: middle;
  background-color: var(--golden);
  color: var(--black);
  border-radius: 0px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  transition: all 200ms ease-in-out;
}

.add_iti .iti_input .input_inner .btn:hover {
  background-color: var(--deep-golden);
}

.sub_title2 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
  color: var(--black);
}

.sailing_date {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 500px;
}

@media (max-width: 992px) {
  .add_iti,
  .sailing_date {
    margin-bottom: 0;
  }
}

.iti_inner {
  display: flex;
  align-items: center;
  gap: 5px 25px;
}

.iti_inner [class*="icheck-"] > label {
  padding-top: 0;
  padding-left: 25px !important;
  font-size: 14px;
  color: var(--deep-grey);
}

.iti_inner
  [class*="icheck-"]
  > input:first-child
  + input[type="hidden"]
  + label::before,
.iti_inner [class*="icheck-"] > input:first-child + label::before {
  width: 17px;
  height: 17px;
  top: 1px;
  transition: all 200ms ease-in-out;
  margin-left: -25px !important;
}

.iti_inner
  [class*="icheck-"]
  > input[type="radio"]:first-child
  + input[type="hidden"]
  + label::before,
.iti_inner
  [class*="icheck-"]
  > input[type="radio"]:first-child
  + label::before {
  border-radius: 0px;
}

.iti_inner
  .icheck-primary
  > input:first-child:not(:checked):not(:disabled):hover
  + input[type="hidden"]
  + label::before,
.iti_inner
  .icheck-primary
  > input:first-child:not(:checked):not(:disabled):hover
  + label::before {
  border-color: var(--red) !important;
}

.iti_inner
  .icheck-primary
  > input:first-child:checked
  + input[type="hidden"]
  + label::before,
.iti_inner .icheck-primary > input:first-child:checked + label::before {
  background-color: var(--red);
  border-color: var(--red);
}

.iti_inner
  [class*="icheck-"]
  > input:first-child:checked
  + input[type="hidden"]
  + label::after,
.iti_inner [class*="icheck-"] > input:first-child:checked + label::after {
  top: -1px;
  left: -2px;
  width: 6px;
  height: 10px;
  transition: all 150ms ease-in-out;
}

.uploaded_item ul {
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.uploaded_item ul li {
  display: block;
  width: 225px;
  height: 150px;
  position: relative;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  border: solid 4px #fff;
}

.uploaded_item ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.uploaded_item ul li button {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  background-color: var(--black);
  color: var(--white);
  border: none;
  opacity: 0;
  transition: all 250ms ease-in-out;
}

.uploaded_item ul li button:hover {
  background-color: var(--red);
}

.uploaded_item ul li:hover button {
  opacity: 1;
}

.css-lo9b43-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  font-weight: 600;
}
