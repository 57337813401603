.section-grey {
  background: #e9e9e9;
}
.title-color {
  color: #D7182A;
  font-weight: 600;
}
.careers {
  background: #FFFFFF;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.careers h5 {
  font-size: 16px;
  color: #727272;
  font-weight: 700;
}
.careers div.resp {
  margin-top: 20px;
}
p.download-itinerary a {
  display: inline-block;
  color: #D7182A;
  text-decoration: none !important;
  margin: 0 10px 10px 0;
  border-radius: 50px;
  border: 1px solid;
  padding: 6px 20px;
}
p.download-itinerary a.langEN {
  display: inline-block !important;
}
