.pay-as-you-go {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
}
.title-grey {
  color: #555555;
}
.title2 {
  font-family: 'Avenir Next Bold';
  font-size: 18px;
  font-weight: 700;
  color: #d7182a;
  font-weight: bold;
}
.pay-as-you-go td.img-cell {
  padding: 0;
  border: 0;
}

.pay-as-you-go td {
  border: 1px solid #ddd;
}
