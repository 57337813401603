.page-tab {
  list-style: none;
  padding: 0;
}
.page-tab > li:after {
  content: "|";
  margin-left: 20px;
  color: #555555 !important;
  font-weight: normal !important;
}
.rounded-top {
  border-top-left-radius:10px!important;
  border-top-right-radius:10px!important;
}
.faqs > div.active p:first-child {
  margin-bottom: 0;
}
.faqs > div p:last-child {
  background: #F7F4F3;
  color: #727272;
  padding: 20px;
  margin-bottom: 0;
}
.faqs > .que p:first-child:after {
  /* content: "\f078"; */
  font-family: 'FontAwesome';
  position: absolute;
  right: 15px;
  color: #D7182A;
}

.faqs > .que p:first-child {
  font-weight: 600;
  cursor: pointer;
  padding: 20px;
  margin-bottom: 0;
  position: relative;
}
.faqs > .que p:first-child {
  border-bottom: 1px solid #D8D8D8;
}
.faq-holder .heading p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}
.faq-holder .heading {
  width: 100%;
  background: #D7182A;
  color: #FFFFFF;
}
.title-grey {
  color: #555555;
  font-weight: 700;
}
.content-info-holder .info {
  background: #FFFFFF;
  min-height: 400px;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius)!important;
  border-top-right-radius: var(--bs-border-radius)!important;
}
.content-info-holder .info > .title {
  background: #D7182A;
  color: #FFFFFF;
  padding: 15px 30px;
}
.content-info-holder .info > .title p {
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 1px;
}
.content-info-holder .info > ol {
  margin: 20px;
}
.d-block {
  display: block!important;
}
.content-info-holder .info > ol li {
  margin-bottom: 5px;
}


.content-info-holder .info > .title p span {
  font-size: 12px;
  font-family: 'Avenir Next Light';
  letter-spacing: 2px;
}
.page-tab > li:last-child:after {
  content: "";
  margin-left: 0;
}
.page-tab > li {
  display: inline-block;
  margin-right: 10px;
  color: #555555;
  cursor: pointer;
  letter-spacing: 2px;
}
.text-center {
  text-align: center!important;
}