.page-tab {
  list-style: none;
  padding: 0;
}
.page-tab > li:after {
  content: "|";
  margin-left: 20px;
  color: #555555 !important;
  font-weight: normal !important;
}
.destination-card p.price {
  color: #ffffff;
  position: absolute;
  bottom: 8px;
  right: 35px;
  font-weight: 700;
  font-size: 22px;
}
.rounded {
  border-radius: 10px !important;
}
.info2 p.sub {
  color: #9d9d9d;
  font-size: 12px;
  letter-spacing: 0.2em;
}
.destination-card {
  position: relative;
  background: #f7f4f3;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
.info2 p strong {
  color: #d7182a;
  font-size: 22px;
}
