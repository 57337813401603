.box-bring {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}
.wifi {
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
}
.wifi #th1 {
  font-family: 'Avenir Next Bold';
  font-size: 18px;
  background-color: #d7182a;
  color: white;
  border: 1px solid #ddd;
  padding: 16px;
  text-align: center;
}
.wifi #th2 {
  background-color: #F7F4F3;
  color: #727272;
  border: 1px solid #ddd;
  padding: 16px;
  text-align: center;
}
.wifi td:first-child {
  width: 480px;
}
.price {
  color: #d7182a;
  font-family: 'Avenir Next Bold';
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
}

.wifi td.center {
  text-align: center;
  vertical-align: middle;
  padding: 0;
}
.wifi td {
  border: 1px solid #ddd;
  padding: 16px 16px 16px 48px;
  text-align: left;
  vertical-align: middle;
}
.wifi #th3 {
  background-color: #F7F4F3;
  color: #727272;
  border: 1px solid #ddd;
  padding: 16px 16px 16px 48px;
  text-align: left;
}
.box-bring .box-header-cruise {
  background: #d7182a;
}
.title-text{
  color:red;
  font-weight:bold;
}
.box-dreamers-club .box-dreamers-club-description {
  padding: 40px;
}
.box-dreamers-club .table th:first-child {
  border-left: none;
}
.box-dreamers-club .table th.text-first {
  padding-left: 40px;
}
.box-dreamers-club .table th {
  background: #f8f4f3;
  border: 1px solid #ccc;
  padding: 15px;
  font-size: 18px;
  color: #666;
}

.text-center {
  text-align: center !important;
}
.box-bring-item .box-bring-item-list:first-child {
  border-right: 1px solid #dee2e6 !important;
}

.box-bring-item .box-bring-item-list {

  width: 50%;
}
.prepare-cruise .box-bring-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}
.box-bring-content {
  background: #fff;
  height: calc(100% - 52px);
  overflow: hidden;
}
.flex-direction-column {
  flex-direction: column;
}
.box-bring-item {
  display: flex;
}
.box-header-cruise h2 {
  font-family: "Avenir Next Bold";
  font-size: 18px;
  color: #fff;
  margin: 0;
}
.box-bring-item .box-bring-item-list .box-bring-content-item:last-child {
  border-bottom: 0 none !important;
}

.box-bring-item .box-bring-item-list .box-bring-content-item {
  border-bottom: 1px solid #dee2e6 !important;
  height: 100%;
}
