.py-md-5 {
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
}
.title-color {
  color: rgb(228, 190, 131);
}
.text-center {
  text-align: center!important;
}
#icon-lists > div.col-md-3 > div > h3 {
  color: rgb(228, 190, 131);
  font-weight: 600;
  margin: 0 auto;
  padding-top: 30px;
}