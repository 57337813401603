.section-grey {
  background: #e9e9e9;
}
.title-color {
  color: #D7182A;
  font-weight: 600;
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-color: gray;
}
table tr th {
  width: 15%;
  border: 1px solid #D8D8D8;
}
table tr td {
  border: 1px solid #D8D8D8;
}
.p-4 {
  padding: 1.5rem!important;
}
tr {
  border-color: inherit;
  border-style: solid;
  border-color: gray;
  border-width: 0;
}
p.download-itinerary a {
  display: inline-block;
  color: #D7182A;
  text-decoration: none !important;
  margin: 0 10px 10px 0;
  border-radius: 50px;
  border: 1px solid;
  padding: 6px 20px;
}
p.download-itinerary a.langEN {
  display: inline-block !important;
}
